<template>
	<div :class="themeClass" class="contBox margin-t-10">
		<div class="newPersonbtn-title margin-t-4 padding-b-13 flex-row-align-center" v-model="myCard">
			<span class="font-size18 font-color-000 font-weight700">我的银行卡</span>
			<span class="font-color-333 margin-l-10">已添加
				<i class="font-color-FF0000">{{myCard.CreditCard}}</i> 张信用卡，
				<i class="font-color-FF0000">{{myCard.CashCart}}</i> 储蓄卡
			</span>
		</div>
		<div class="newPersonbtn-Box">
			<div class="text-center font-size16 margin-b-25">{{pageTitle}}（必填）</div>
			<el-form :model="addressForm" :rules="rules" ref="addressForm" label-width="150px" class="demo-ruleForm">
				<el-form-item label="持卡人姓名" prop="name">
					<el-input v-model="addressForm.name" placeholder="请输入收货人"></el-input>
				</el-form-item>
				<el-form-item label="身份证号" prop="IdNumber">
					<el-input v-model="addressForm.IdNumber" placeholder="请输入身份证号"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号" prop="bankCard">
					<el-input v-model="addressForm.bankCard" placeholder="请输入银行卡号"></el-input>
				</el-form-item>
				<el-form-item label="开户行" prop="bankDeposit">
					<el-select v-model="addressForm.bankDeposit" class="selectBox" clearable placeholder="请选择开户行">
						<el-option v-for="item in carToolList" :key="item.carId" :label="item.carName" :value="item.carId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="银行预留号码" prop="telephone">
					<el-input v-model="addressForm.telephone" type="number" placeholder="请输入手机号码"></el-input>
				</el-form-item>
				<el-form-item label="设置为默认快捷支付" prop="valueSwitch">
					<el-switch v-model="addressForm.valueSwitch"></el-switch>
				</el-form-item>
				<el-form-item label="" prop="">
					<div class="manualText bg-F2F4FD radius10 font-size11 font-color-865D2A">
						<p>*操作说明：</p>
						<p>1.第一步填写需要开通的银行卡信息点击[发送验证码]按纽</p>
						<p>2.第二步将银行卡所绑定的手机收到的短信验证码填写到[验证码]的地方,并点击[验证并开通]按纽</p>
						<p>3.如果验证开通失败,再点击[重新发送]按纽,重复第二步操作.</p>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm('addressForm')">保存</div>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div><!-- 背景图 -->
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			var checkMaximum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('重量不能为空'));
				}
				setTimeout(() => {
					if (value < 10) {
						callback(new Error('重量必须大于10'));
					} else {
						callback();
					}

				}, 1000);
			};
			const rulesloginId = (rule, value, callback) => {
				if (!/^1[34578]\d{9}$/.test(value)) {
					callback('手机号不正确')
				} else {
					callback()
				}
			}
			return {
				pageTitle: "新增银行卡",
				carToolType: "",
				carToolList: [{
						carName: "中国银行",
						carId: 1
					},
					{
						carName: "上海银行",
						carId: 2
					},

				],
				addressForm: {
					name: '',
					IdNumber: '',
					bankCard: '',
					bankDeposit: '', //开户行
					telephone: '',
					valueSwitch: false
				},
				myCard: {
					CreditCard: 3, //信用卡
					CashCart: 2 //储蓄卡
				},
				rules: {
					name: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 10,
							message: '长度在 2 到 10 个字符',
							trigger: 'blur'
						}
					],
					IdNumber: [{
							required: true,
							message: '请输入身份证号',
							trigger: 'blur'
						},
						{
							min: 15,
							max: 18,
							message: '请填写18位号码',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/,
							message: '请输入正确的身份证号码',
							trigger: 'blur'
						}
					],
					telephone: [{
							required: true,
							message: '手机号不能为空',
							trigger: 'blur'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					bankCard: [{
						required: true,
						message: '银行卡不能为空',
						trigger: 'blur'
					}, {
						min: 13,
						max: 19,
						message: '银行卡号13~19位',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([1-9]{1})(\d{12}|\d{18})$/,
						message: '请输入正确的银行卡号',
						trigger: 'blur'
					}],
					bankDeposit: [{
						required: true,
						message: '开户行信息不能位空',
						trigger: 'blur'
					}]

				}
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {

		},
		created() {
			this.pageTitle = this.$route.query.pageType;
			//this.getTransportToolType();
			if (this.pageTitle == "编辑车辆信息") {

			}
		},
		methods: {
			//查询所有启用运输工具类型
			getTransportToolType() {
				this.ApiRequestPost('', {}).then(
					result => {
						//this.carToolList = result.obj.items;
					},
					rej => {}
				);
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$router.push({
							path: '/PersonalContent/MyBankCard',
							query: '',
						});

					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				//this.$refs[formName].resetFields();
				this.$router.back();

			}

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 56%;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
	}

	.newPersonbtn-title {
		height: 22px;
		color: #000000;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	/* 按钮 */
	.comBtn {
		width: 160px;
		height: 38px;
		line-height: 38px;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
	}

	i {
		font-style: normal;
	}

	.manualText {
		padding: 20px 15px;

		p {
			line-height: 19px;
		}
	}
</style>
